import { yupResolver } from '@hookform/resolvers/yup';
import { ERROR_MESSAGE } from 'constants/form-constants';
import * as yup from 'yup';

export const FORM_FIELDS = {
    EMAIL: 'email',
    GDPR_REQUEST: 'gdprRequest',
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.EMAIL]: yup
            .string()
            .required(ERROR_MESSAGE.REQUIRED_FIELD)
            .email(ERROR_MESSAGE.EMAIL),
        [FORM_FIELDS.GDPR_REQUEST]: yup
            .string()
            .required(ERROR_MESSAGE.REQUIRED_FIELD),
    }),
);
