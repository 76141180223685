import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { MainTitle } from 'components/main-title';
import { Overlay } from 'components/overlay';
import { CONSTANTS } from 'constants/styles/constants';

import { Content } from './components/content';
import { GDPRSidebar } from './components/gdpr-sidebar';

const SContainer = styled(Container)`
    margin-top: ${CONSTANTS.MARGIN.PAGE};
`;
const SPrivacyPolicyView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-bottom: 2rem;
    text-align: center;
`;

const SContentWrapper = styled.div`
    display: flex;
    gap: 45px;
`;

const SHeaderThird = styled(HeaderSecond)`
    font-size: 1.2rem;
    margin-right: auto;
`;

export const PrivacyPolicyView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const blogPostContentRef = useRef();
    const { formatMessage } = useIntl();

    // TODO convert floating sidebar to global component with props { offsetY, ... }
    // Component used here and in views/blogPost

    return (
        <SContainer>
            <SPrivacyPolicyView>
                <SHeader>
                    <MainTitle>
                        <FormattedMessage id="view-privacy-policy.header" />
                    </MainTitle>
                    <SHeaderThird>
                        <FormattedMessage id="view-privacy-policy.subheader" />
                    </SHeaderThird>
                </SHeader>
                <SContentWrapper ref={blogPostContentRef}>
                    <Content />
                    <GDPRSidebar
                        formatMessage={formatMessage}
                        setIsLoading={setIsLoading}
                        blogPostContentRef={blogPostContentRef}
                    />
                </SContentWrapper>
                <Overlay show={isLoading} />
            </SPrivacyPolicyView>
        </SContainer>
    );
};
